import React, { useState, useEffect } from "react";
import { Button, Col, Row, Checkbox, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { api_url } from '../components/constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    // Retrieve saved credentials if "Keep me logged in" was checked
    const savedRememberMe = localStorage.getItem('rememberMe') === 'true';
    const savedEmail = localStorage.getItem('savedEmail');
    const savedPassword = localStorage.getItem('savedPassword');

    if (savedRememberMe && savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);

      // ✅ Ensure form updates include the checkbox value
      form.setFieldsValue({
        email: savedEmail,
        password: savedPassword,
        rememberMe: true
      });
    } else {
      form.setFieldsValue({ rememberMe: false });
    }
  }, [form]);

  useEffect(() => {
    const loginUser = JSON.parse(localStorage.getItem('user'));
    if (loginUser && loginUser.user) {
      const { userType } = loginUser.user;
      if (userType === "Student") {
        navigate("/student-dashboard/dashboard");
      } else if (userType === "Counsellor") {
        navigate("/counsellor-dashboard/dashboard-home");
      } else if (userType === "Faculty") {
        navigate("/teacher-dashboard/dashboard");
      } else {
        navigate("/student-dashboard/dashboard");
      }
    }
  }, [navigate]);

  const handleLogin = async (values) => {
    try {
      const response = await axios.post(api_url + 'auth/login', {
        email: values.email,
        password: values.password
      });

      if (response.data.status) {
        const userLogin = {
          token: response.data.access_token,
          user: response.data.user
        };
        localStorage.setItem('user', JSON.stringify(userLogin));

        if (values.rememberMe) {
          localStorage.setItem('savedEmail', values.email);
          localStorage.setItem('savedPassword', values.password);
          localStorage.setItem('rememberMe', 'true');
        } else {
          localStorage.removeItem('savedEmail');
          localStorage.removeItem('savedPassword');
          localStorage.removeItem('rememberMe');
        }

        const userType = response.data.user.userType;
        if (userType === "Student") {
          navigate("/student-dashboard/dashboard");
        } else if (userType === "Counsellor") {
          navigate("/counsellor-dashboard/dashboard-home");
        } else if (userType === "Faculty") {
          navigate("/teacher-dashboard/dashboard");
        } else {
          navigate("/student-dashboard/dashboard");
        }
      } else {
        toast.error(response.data.message, { position: toast.POSITION.TOP_CENTER });
      }
    } catch (error) {
      toast.error("Login failed. Please try again.", { position: toast.POSITION.TOP_CENTER });
    }
  };

  return (
    <>
      <ToastContainer />
      <img className="wave" src="images/wave.png" alt='wave-img' />
      <div className="login">
        <div className="login-container md:mx-5">
          <Row gutter={40}>
            <Col md={12} xs={24}>
              <img src="images/allen-global-logo.png" style={{ width: 200 }} alt='allen-global-logo' className="for-mobile" />
              <h1 className="text-[#0F4275] text-2xl text-center font-medium mb-0">Login Portal</h1>
              <p className="text-[#8A8181] text-lg text-center font-light">
                <b style={{ color: '#000000', fontWeight: '500' }}>Think Study Abroad, Think ALLEN Global</b>
              </p>
              <img src="images/login-img.png" alt='login-img' className="mb-5 md:mb-0" />
            </Col>
            <Col md={12} xs={24}>
              <Col span={24}>
                <div className="mb-7 flex justify-center">
                  <img src="images/allen-global-logo.png" style={{ width: 200 }} alt='allen-global-logo' className="for-web" />
                </div>
              </Col>
              <Form
                name="normal_login"
                form={form}
                className="login-form"
                initialValues={{ rememberMe: rememberMe }}
                onFinish={handleLogin}
                onFinishFailed={(errorInfo) => console.log('Validation error:', errorInfo)}
              >
                <Col md={24} xs={24} lg={22}>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please enter your username!' }]}
                  >
                    <Input
                      suffix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col md={24} xs={24} lg={22}>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Please input your Password!' }]}
                  >
                    <Input
                      suffix={<LockOutlined className="site-form-item-icon" />}
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col md={24} xs={22}>
                  <Form.Item name="rememberMe" valuePropName="checked">
                    <Checkbox
                      className="text-sm text-[#808080]"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    >
                      Keep me logged in
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col md={24} xs={24} lg={22}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-btn text-xl text-[#0D3761] bg-[#FCC222]"
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Col>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Login;
