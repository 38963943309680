import React, { useEffect, useState } from 'react';
import '../styles/dashboard.css';
import { Button, Col, Row, Layout, Modal, Form, Input, Select } from 'antd';
import DashboardLayout from '../components/dashboard-layout';
import axios from 'axios';
import { api_url } from '../components/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { Content } = Layout;


const RaiseTicket = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));

    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;
    const [loading, setLoading] = useState(false);
    const [doubtLoading, setDoubtLoading] = useState(false);
    const [queryData, setQueryData] = useState([]);
    const [queryModalVisible, setQueryModalVisible] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);



    const fetchUserQueryList = async () => {
        await axios.post(api_url + 'ajax/get-user-query-request', {
            user_id: user_data.id

        }).then((res) => {
            if (res.data.status) {
                const query_data = res.data.query_list;
                setQueryData(query_data);
            }
            //setDoubtLoading(false)
        })
    }

    useEffect(() => {
        fetchUserQueryList()
    }, [])

    const handleApi = () => {
        if (!queryType || queryType.trim() === "") {
            toast.error('Enter Your Query Type', {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        if (!query || query.trim() === "") {
            toast.error('Enter Your Query', {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        setButtonLoading(true);

        axios.post(api_url + 'ajax/save-user-query', {
            query: query,
            query_type: queryType,
            user_id: user_data.id,
        })
            .then(result => {

                setButtonLoading(false);

                if (result.data.status) {

                    setDoubtLoading(false);
                    toast.success(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                    setQueryModalVisible(false);
                    // Reload the page after 5 seconds (5000 ms)
                    setTimeout(() => {
                        window.location.reload();
                    }, 5000);
                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch(error => {
                console.error("API Error:", error);
                toast.error("Something went wrong!", {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    };


    const [query, setQuery] = useState('');
    const [queryType, setQueryType] = useState('');

    const handleQueryChange = (value) => {
        setQueryType(value);
    };

    const handleQuery = (e) => {
        setQuery(e.target.value);
    };


    return (
        <>
            <DashboardLayout>
                {loading ? (
                    <div className="loaders"><img alt='loader' src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background lg:p-7 md:p-5"
                        style={{
                            background: '#fff',
                            width: '100%'
                        }}
                    >
                        <div className="mb-0 mx-0">
                            <Row>
                                <Col md={24}>
                                    <div className="form-layout form-space">
                                        <div className="bg-[#0F4275] py-3 px-5 md:px-7 flex flex-col md:flex-row justify-between items-center">
                                            <h1 className="text-white text-xl md:text-2xl uppercase text-center md:text-left">Ticket List</h1>

                                            {/* <button type='button' className="text-white text-xl bg-red p-0 m-0 cursor-pointer" onClick={() => setQueryModalVisible(true)}>Raise a query</button> */}
                                            <Button type="primary"
                                                className="text-sm md:text-lg text-white bg-[#F0AA0C] px-5 md:px-7 flex justify-center"
                                                style={{ height: 'auto', borderRadius: 20 }}
                                                onClick={() => setQueryModalVisible(true)}

                                            >Raise a query</Button>
                                        </div>
                                        <div>
                                            {loading ? (
                                                <p>Loading...</p>
                                            ) : (
                                                <Modal
                                                    title="Raise a query"
                                                    open={queryModalVisible}
                                                    okText='Raise Query'
                                                    onOk={handleApi}
                                                    onCancel={() => setQueryModalVisible(false)}
                                                    okButtonProps={{
                                                        style: { background: '#F0AA0C', borderRadius: 9, padding: '2px 20px 30px', fontSize: 16 },
                                                        loading: buttonLoading // Show the loading spinner when buttonLoading is true
                                                    }}
                                                    cancelButtonProps={{ style: { background: '#717070', borderRadius: 9, padding: '2px 20px 30px', fontSize: 16, color: '#fff' } }}
                                                >
                                                    <Form>
                                                        {/* Query Type Dropdown */}
                                                        <Row gutter={30} style={{ paddingLeft: 20 }}>
                                                            <Col md={24}>
                                                                <Form.Item
                                                                    name="query_type"
                                                                    label="Select Type"
                                                                    wrapperCol={{ span: 24 }}
                                                                    labelCol={{ span: 24 }}
                                                                    rules={[{ required: true, message: 'Query Type is required!' }]}
                                                                >
                                                                    <Select
                                                                        style={{ width: '100%' }}
                                                                        placeholder="Select Issue Type"
                                                                        onChange={handleQueryChange} // No need for (e) => e.target.value
                                                                        options={[
                                                                            { value: 'Class Related Query', label: 'Class Related Query' },
                                                                            // { value: 'PTM Related Query', label: 'PTM Related Query' },
                                                                            { value: 'PBS Related Query', label: 'PBS Related Query' },
                                                                            { value: 'College Application Related Query', label: 'College Application Related Query' },
                                                                            { value: 'Technical Issue', label: 'Technical Issue' },
                                                                            { value: 'Other', label: 'Other' }
                                                                        ]}
                                                                    />
                                                                </Form.Item>

                                                            </Col>
                                                        </Row>

                                                        {/* Query Textarea */}
                                                        <Row gutter={30} style={{ paddingLeft: 20 }}>
                                                            <Col md={24}>
                                                                <Form.Item
                                                                    name="query_text"
                                                                    wrapperCol={{ span: 24 }}
                                                                    labelCol={{ span: 24 }}
                                                                    label="Enter Your Query"
                                                                    rules={[{ required: true, message: 'Query is required!' }]} // Required validation

                                                                >
                                                                    <Input.TextArea
                                                                        rows={4}
                                                                        placeholder="Type your query here..."
                                                                        onChange={handleQuery}
                                                                        value={query} // Ensure controlled component

                                                                    />
                                                                </Form.Item>

                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Modal>

                                            )}
                                        </div>

                                        <div className='px-2 py-3'>

                                            {doubtLoading ? (
                                                <div className="loaders">
                                                    <img alt='loader' src='/images/loader.gif' />
                                                </div>
                                            ) : (
                                                queryData.length > 0 ? (

                                                    queryData.map((row, index) => (
                                                        <Row key={index} className="w-full">
                                                            <Col md={24}>
                                                                <div className="md:flex flex-wrap bg-[#F2EEEB] px-5 py-5 m-1.5 rounded-lg">

                                                                    {/* Type Column */}

                                                                    <div className="column-style w-full md:w-1/4 mb-3 md:mb-0">
                                                                        <h4 className="text-[#F1AC12] text-base md:text-lg break-words">
                                                                            {row.type}
                                                                        </h4>
                                                                    </div>


                                                                    {/* Query Column with "Read More" */}
                                                                    <div className="column-style w-full md:flex-1 md:min-w-[250px] mb-3 md:mb-0">
                                                                        <h4
                                                                            id={`query-text-${index}`}
                                                                            className="text-[#F1AC12] text-base md:text-lg break-words overflow-hidden"
                                                                            style={{
                                                                                display: "-webkit-box",
                                                                                WebkitBoxOrient: "vertical",
                                                                                WebkitLineClamp: 2,
                                                                                wordBreak: "break-word", // Prevents long words from overflowing
                                                                                transition: "all 0.3s ease"
                                                                            }}
                                                                        >
                                                                            {row.query}
                                                                        </h4>

                                                                        {row.query.length > 50 && (
                                                                            <button
                                                                                className="text-blue-500 text-sm underline ml-1"
                                                                                onClick={(e) => {
                                                                                    const textElement = document.getElementById(`query-text-${index}`);
                                                                                    if (textElement.style.WebkitLineClamp === "2") {
                                                                                        textElement.style.WebkitLineClamp = "unset";
                                                                                        e.target.innerText = "Read Less";
                                                                                    } else {
                                                                                        textElement.style.WebkitLineClamp = "2";
                                                                                        e.target.innerText = "Read More";
                                                                                    }
                                                                                }}
                                                                                ref={(btn) => {
                                                                                    const textElement = document.getElementById(`query-text-${index}`);
                                                                                    if (textElement && btn) {
                                                                                        const isOverflowing = textElement.scrollHeight > textElement.clientHeight;
                                                                                        btn.style.display = isOverflowing ? "inline" : "none";
                                                                                    }
                                                                                }}
                                                                            >
                                                                                Read More
                                                                            </button>
                                                                        )}
                                                                    </div>

                                                                    {/* Date Column */}
                                                                    <div className="column-style w-full md:w-auto text-center md:text-left mb-3 md:mb-0">
                                                                        <h4 className="text-[#F1AC12] text-base md:text-lg break-words">{row.created_at}</h4>
                                                                    </div>

                                                                    {/* Status Button */}
                                                                    <div className="column-style-no-border w-full md:w-auto text-center">
                                                                        <Button className="bg-[#EC9655] text-white rounded-full text-sm md:text-base">
                                                                            {row.status}
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    ))

                                                ) : (
                                                    <div className="my-24">
                                                        <h3 className="text-xl text-[#F0AA0C] text-center font-semibold">No Record Found!</h3>
                                                    </div>
                                                )
                                            )}

                                        </div>


                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Content>
                )
                }
            </DashboardLayout>
        </>
    )
}

export default RaiseTicket