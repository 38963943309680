import React, { useEffect, useState } from 'react';

import { Layout, Menu, Button, Dropdown, Calendar, Modal, Form, Col, Row, Select, Input, DatePicker, Space } from 'antd';
import {
    DownOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import Footer from '../components/footer';
import { Link, useLocation } from 'react-router-dom';
import { setAuthToken } from '../helpers/setAuthToken';
import { encriptionKey, decrypt } from "./utils";
import { api_url, studentPath } from '../components/constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/responsive.css';
const { Header, Sider, Content } = Layout;

const DashboardLayout = ({ children }) => {
    let date = new Date().toLocaleDateString('en-us', { day: "numeric" });
    let day = new Date().toLocaleDateString('en-us', { weekday: "long" });
    let month = new Date().toLocaleDateString('en-us', { month: "long" });
    let year = new Date().getFullYear();
    const path = window.location.pathname;
    const login_user = JSON.parse(localStorage.getItem('user'));
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true)
    const [form] = Form.useForm();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [pbsMoadalVisible, setPbsMoadalVisible] = useState(false);
    const [tymodalVisible, setTyModalVisible] = useState(false);
    const [pbsDate, setPbsDate] = useState('');
    const [pbsTime, setPbsTime] = useState('');
    const [timeSlot, settimeSlot] = useState({ label: '', value: '' });
    const [events, setEvents] = useState([{ id: "", date: "", time: "", status: "", link: "" },]);
    const [pbsEvent, setPBSEvent] = useState({ date: "", time: "", counsellor: "", status: "", id: "", link: "" });
    const [calandermodalVisible, setCalanderMoadalVisible] = useState(false);
    const [PassModalvisible, setPassModalvisible] = useState(false);
    const location = useLocation();
    const [openKeys, setOpenKeys] = useState([]);
    const currentPath = location.pathname;

    // const [notifications, setNotificationEvents] = useState([{ id: "", title: "", type: "", created_at: "" },]);

    let course = decrypt(user_data.course, encriptionKey);

    const handleDateChange = (value) => {
        const formattedDate = value.format('YYYY-MM-DD');
        setPbsDate(formattedDate);
    };
    const handleTimeChange = (value) => {
        setPbsTime(value);
    };
    const fetchUserSteps = async () => {

        await axios.post(api_url + 'get_student_step_flag', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                } else {
                    // if (class_name === 9 || class_name === 10) {
                    //     if (user_data.diagnostic_test === 0) {
                    //         window.location.href = '/student-portal/diagnostic-guideline';
                    //     } else {
                    //         window.location.href = '/student-portal/general-enquiry';
                    //     }

                    // }else {
                    //     window.location.href = '/student-portal/general-enquiry';
                    // }

                }
            } else {

                if (res.data.term === 0) {
                    //console.log("correct");
                } else {

                    // if (res.data.gef_status == "0" || res.data.giq_status == "0" || res.data.academic_status == "0") {
                    //     navigate("/student-portal/general-enquiry");
                    // }
                }

            }
            setLoading(false)

        })
    }
    const logout = () => {
        localStorage.removeItem('user')
        setIsLoggedIn(false);
        // navigate('/');
    }

    const fetchEventData = async () => {
        await axios.post(api_url + 'user/get_event_data', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const pbs_data = res.data.data;

            setEvents(pbs_data);
            setLoading(false)

        })
    }
    const getTimeSlot = async () => {

        await axios.post(api_url + 'pbs-time-slot', {
            user_id: user_data.id

        }).then((res) => {

            if (!res.data.status) {
                if (res.data.access_token == null) {
                    localStorage.removeItem('user')
                    window.location.href = '/';
                }
            }
            const time_slot = res.data.data.map((element) => ({
                label: element.slot,
                value: element.slot
            }));

            // Update the state with the transformed time_slot data
            settimeSlot(time_slot);
            setLoading(false);
        })

    }

    useEffect(() => {
        fetchUserSteps()
        fetchEventData()
        getTimeSlot()
        // getUserNotification()
    }, []) // eslint-disable-line

    const access_token = JSON.parse(localStorage.getItem('user'));
    if (access_token) {
        setAuthToken(access_token.token);
    }

    const className = decrypt(user_data.class, encriptionKey);
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    const TyModalVisibleNew = () => {
        setTyModalVisible(false);
    }

    const handleApi = () => {
        axios.post(api_url + 'pbs-request', {
            date: pbsDate,
            time: pbsTime,
            user_id: user_data.id,
        })
            .then(result => {
                if (result.data.status) {
                    setPbsMoadalVisible(false);
                    setTyModalVisible(true);

                } else {
                    toast.error(result.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });

                }
            })
    }

    const dateCellRender = (date) => {
        const formattedDate = date.format('YYYY-MM-DD');
        const eventsForDate = events.filter((e) => e.date === formattedDate);

        if (eventsForDate.length > 0) {
            return (
                <ul className="events">
                    {eventsForDate.map((element) => (
                        <li key={element.id}>
                            {`Event - ${element.status}`}
                        </li>
                    ))}
                </ul>
            );
        }
        return null;
    };

    const onSelect = (date) => {
        const formattedDate = date.format('YYYY-MM-DD');

        axios.post(api_url + 'get_student_pbs_request', {
            user_id: user_data.id,
            event_date: formattedDate,

        }).then((res) => {

            if (res.data.status) {
                let pbs_cal_req = JSON.parse(res.data.data);
                setPBSEvent(pbs_cal_req);
                setLoading(false)
                setCalanderMoadalVisible(true);
            }

        })
    };

    const allMenuItems = [
        {
            title: 'Dashboard',
            path: '/student-dashboard/dashboard',
            icon: '/images/calendar-img.png',
        },
        {
            title: 'My Profile',
            icon: '/images/application.png',
            subMenu: [
                { title: 'General Enquiry Form', path: '/student-dashboard/gef' },
                { title: 'GIQ Form', path: '/student-dashboard/giq' },
                { title: 'Profile', path: '/student-dashboard/profile-format' },
                { title: 'Test Score Records', path: '/student-dashboard/test-record' },
            ],
        },
        {
            title: 'Profile Building',
            icon: '/images/PBS-session.png',
            subMenu: [
                { title: 'PBS Session', path: '/student-dashboard/pbs-session' },
                { title: 'All PBS Suggestions', path: '/student-dashboard/my-timeline' },
            ],
        },
        {
            title: 'My Classes',
            icon: '/images/my-classes.png',
            subMenu: [
                { title: 'Regular Classes', path: '/student-dashboard/my-classes-new' },
                { title: 'Doubt Classes', path: '/student-dashboard/doubt-class' },
                { title: 'Extra Classes', path: '/student-dashboard/extra-class' },
            ],
        },
        {
            title: 'PTM & Doubt Session',
            icon: '/images/doubt.png',
            subMenu: [
                { title: 'PTM Session', path: '/student-dashboard/ptm-session', icon: '/images/PBS-session.png' },
                { title: 'Doubt Session', path: '/student-dashboard/doubt-section', icon: '/images/doubt.png' },
            ],
        },
        {
            title: 'Study Material',
            path: '/student-dashboard/study-material',
            icon: '/images/test-records.png',
        },
        {
            title: 'Assessment',
            path: '/student-dashboard/assessment',
            icon: '/images/assessment-report.png',
        },
        {
            title: 'New Batch Request',
            path: '/student-dashboard/batch-request',
            icon: '/images/doubt.png',
        },
        {
            title: 'Raise a query',
            path: '/student-dashboard/raise-ticket',
            icon: '/images/doubt.png',
        },
    ];

    if (user_data.application_flag === 1) {
        allMenuItems.push({
            title: 'Application',
            path: '/student-dashboard/application',
            icon: '/images/application.png',
        });
    }
    const class9MenuItems = [

        {
            title: 'Psychometric',
            path: '/student-dashboard/psychometric',
            icon: '/images/immigration.png',
        },
        {
            title: 'Speed Reading Tools',
            path: '/student-dashboard/reading-tool',
            icon: '/images/immigration.png',
        },
        {
            title: 'Speed Reading Tools Practice',
            path: '/student-dashboard/reading-tool-practice',
            icon: '/images/immigration.png',
        },

    ];

    const class10MenuItems = [
        {
            title: 'Psychometric',
            path: '/student-dashboard/psychometric',
            icon: '/images/immigration.png',
        },
        {
            title: 'Speed Reading Tools',
            path: '/student-dashboard/reading-tool',
            icon: '/images/immigration.png',
        },
        {
            title: 'Speed Reading Tools Practice',
            path: '/student-dashboard/reading-tool-practice',
            icon: '/images/immigration.png',
        },

    ];

    let menuItems = [];

    if (className === 9 || className === "9") {
        menuItems = allMenuItems.concat(class9MenuItems);
    } else if (className === 10 || className === "10") {
        menuItems = allMenuItems.concat(class10MenuItems);
    }


    else {
        menuItems = allMenuItems;
    }

    const items = [
        {
            key: '1',
            label: (
                <a href='/' onClick={logout}>Logout</a>
            ),
        },
        {
            key: '2',
            label: (
                <button className='bg-transparent border-none p-0 m-0 cursor-pointer' onClick={() => setPassModalvisible(true)}>Change Password</button>
            ),
        },
    ]


    const handleCancel = () => {
        form.resetFields();
        setPassModalvisible(false)
    };

    const handlePassword = async (values) => {
        try {
            const res = await axios.post(api_url + 'change-password', {
                user_id: user_data.id,
                password: values.currentPassword,
                new_password: values.newPassword,
                confirm_password: values.confirmPassword
            });

            // console.log(res); // log the response data to the console
            if (res.data.status) {

                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                const timer = setTimeout(() => { // eslint-disable-line
                    setPassModalvisible(false)
                    window.location.reload();
                }, 3000);

            } else {
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });

            }

        } catch (error) {
            //console.log(error);
        }
    };


    useEffect(() => {
        const openKey = menuItems.reduce((acc, item, index) => {
            if (item.subMenu) {
                item.subMenu.forEach(subItem => {
                    if (subItem.path === currentPath) {
                        acc.push(`sub-${index}`);
                    }
                });
            }
            return acc;
        }, []);
        setOpenKeys(openKey);
        const handleResize = () => {
            setCollapsed(window.innerWidth <= 1024);
        };

        handleResize(); // Call the function once to set the initial state

        window.addEventListener('resize', handleResize); // Listen for window resize events

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []); // eslint-disable-line

    const handleOpenChange = keys => {
        setOpenKeys(keys);
    };

    return (
        <div className="dashboardSection">
            <ToastContainer />
            <Layout>

                <Header className='sticky top-0 z-10'
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: '1px solid #F1F1F1',
                        padding: '0 24px',
                        width: '100%',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            className="trigger"
                            onClick={toggleCollapse}
                            style={{ marginRight: '16px' }}
                        >
                            {collapsed ? (
                                <MenuUnfoldOutlined className='text-2xl' />
                            ) : (
                                <MenuFoldOutlined className='text-2xl' />
                            )}
                        </div>
                        <img
                            src="/images/allen-global-logo.png"
                            width="150px"
                            alt="company logo"
                        // style={{ marginRight: '10px', paddingBottom: 10 }}
                        />
                        <ExclamationCircleOutlined className='flex justify-center items-center text-[#0F4275] text-xl pl-24 dashboard-menu-title' />
                        <h1 className="text-[#F0AA0C] text-lg pl-2.5 dashboard-menu-title">
                            {'Student Dashboard'}
                        </h1>
                    </div>
                    <div className="flex items-center">
                        <div className="flex justify-center items-center">
                            {user_data.image == null ? (
                                <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                            ) : (
                                <img src={studentPath + user_data.image} className="user-img flex justify-start items-center" alt="user avatar" />
                            )}

                        </div>

                        <Dropdown menu={{ items }} className='pt-5'>
                            <DownOutlined className='flex justify-center items-center pl-2.5' />
                        </Dropdown>
                        <Modal
                            open={PassModalvisible}
                            title="Change Password"
                            onCancel={handleCancel}
                            footer={null}
                        >
                            <Form form={form} onFinish={handlePassword}>
                                <Form.Item
                                    name="currentPassword"
                                    label="Current Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your current password!',
                                        },
                                    ]}
                                >
                                    <Input.Password name="password" />
                                </Form.Item>
                                <Form.Item
                                    name="newPassword"
                                    label="New Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your new password!',
                                        },
                                    ]}
                                >
                                    <Input.Password name="new_password" />
                                </Form.Item>
                                <Form.Item
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    dependencies={['newPassword']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your new password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('newPassword') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password name="confirm_password" />
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 6 }}>
                                    <Button type="primary" htmlType="submit" className='bg-[#F0AA0C]'>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </div>
                </Header>

                <Layout>

                    <Sider
                        width={300}
                        trigger={null}
                        style={{ height: '93vh' }}
                        id="main-sidebar"
                        collapsible
                        collapsed={collapsed}
                        //onCollapse={toggleCollapse}
                        onCollapse={setCollapsed}
                        className={collapsed ? 'collapsed-sider' : ''}
                    >

                        <div className={`flex my-5 ${collapsed ? 'collapsed-sider-content' : ''}`}>
                            {user_data.image == null ? (
                                collapsed ? null : <img src='/images/avatar.png' className="user-img flex justify-start items-center" alt="user avatar" />
                            ) : (
                                <img src={studentPath + user_data.image} className="user-img flex justify-start items-center" alt="user avatar" />
                            )}

                            {!collapsed && (
                                <div className='pl-2.5 pb-5'>
                                    <h3 className='text-[#707070] text-base'>{decrypt(user_data.name, encriptionKey)}</h3>
                                    <button
                                        className='text-[#F0AA0C] text-base bg-transparent border-none p-0 m-0 cursor-pointer'
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevent default anchor behavior
                                            setPbsMoadalVisible(true);
                                        }}
                                    >Schedule PBS Session</button>
                                </div>
                            )}
                        </div>

                        {pbsMoadalVisible && (
                            <Modal title="Schedule PBS Session"

                                open={pbsMoadalVisible}
                                okText='Schedule PBS'
                                onOk={() => {
                                    handleApi()
                                }}
                                onCancel={() => setPbsMoadalVisible(false)}
                                okButtonProps={{ style: { background: '#F0AA0C', borderRadius: 9, padding: '2px 20px 30px', fontSize: 16 } }}
                                cancelButtonProps={{ style: { background: '#717070', borderRadius: 9, padding: '2px 20px 30px', fontSize: 16, color: '#fff' } }}
                            >
                                <Form>
                                    <Row gutter={30} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: 20 }}>
                                        <Col md={12}>
                                            <Form.Item
                                                name="pbs_date"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                label="Select Date"
                                                initialValue={pbsDate.pbs_date}
                                            >
                                                <Space direction="vertical" style={{ width: '100%' }}>
                                                    <DatePicker onChange={handleDateChange} style={{ width: '100%' }} />
                                                </Space>
                                            </Form.Item>
                                        </Col>

                                        <Col md={12}>
                                            <Form.Item
                                                name="pbs_time"
                                                label="Select Time"
                                                wrapperCol={{ span: 24 }}
                                                labelCol={{ span: 24 }}
                                                initialValue={pbsTime.pbs_time}
                                            >

                                                <Select
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    placeholder="Select Slot"
                                                    onChange={handleTimeChange}
                                                    options={timeSlot}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                            </Modal>
                        )}
                        {calandermodalVisible && (
                            <Modal title="PBS Schedule Details" width={800} open={calandermodalVisible}
                                onCancel={() => setCalanderMoadalVisible(false)}
                                okButtonProps={{ style: { display: 'none' } }}
                                cancelButtonProps={{ style: { display: 'none' } }}
                            >

                                <table className='curricular'>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Mentor</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{pbsEvent.date}</td>
                                            <td>{pbsEvent.time}</td>
                                            <td>{pbsEvent.counsellor ? (decrypt(pbsEvent.counsellor, encriptionKey)) : ('Mentor Not Assign')}</td>
                                            <td>{pbsEvent.status}</td>
                                            <td>
                                                {pbsEvent.status === "Pending" ? (
                                                    <>
                                                        <Button className="text-md text-white bg-[#F0AA0C]  mx-1.5"
                                                            style={{ height: 'auto', borderRadius: 20, display: 'flex', justifyContent: 'center', color: '#fff' }}>Pending</Button>

                                                    </>
                                                ) : ('')

                                                }
                                                {pbsEvent.status === "Complete" ? (

                                                    <Link
                                                        className="text-md text-white bg-[#F0AA0C] py-1.5 px-7 flex justify-center items-center mr-2.5"
                                                        style={{ height: 'auto', borderRadius: 20 }}
                                                    >
                                                        View
                                                    </Link>
                                                ) : ('')

                                                }

                                                {pbsEvent.status === "Approved" && pbsEvent.link != null && pbsEvent.counsellor != null ? (
                                                    <a
                                                        style={{ height: 'auto', borderRadius: 20 }}
                                                        className="text-lg text-white bg-[#F0AA0C] py-1.5 px-7 justify-center items-center mr-2.5"
                                                        rel="noreferrer noopener"
                                                        target="_blank" href={pbsEvent.link}>Join Session</a>
                                                ) : ('')

                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Modal>
                        )}
                        <Modal title={<img alt='mail' src='/images/send-mail.png' />} open={tymodalVisible}
                            onOk={TyModalVisibleNew}
                            onCancel={() => setTyModalVisible(false)}
                            okButtonProps={{ style: { background: '#0F4275', color: '#fff', borderRadius: 7 } }}
                            cancelButtonProps={{ style: { display: 'none' } }}
                        >
                            <h4 className="text-[#2A6496] text-xl text-center">PBS Request</h4>
                            <h3 className="text-[#FFC040] text-2xl text-center">Successfully Send</h3>
                            <p className="text-[#5B5B5B] text-base text-center">Your counsellor contact to you very soon</p>
                            <p className="text-[#5B5B5B] text-base text-center">Thank You !!</p>
                        </Modal>

                        <Menu
                            theme='light'
                            mode="inline"
                            selectedKeys={[currentPath]}
                            openKeys={openKeys}
                            onOpenChange={handleOpenChange}
                            className={collapsed ? 'collapsed-menu' : ''}
                        >
                            {menuItems.map((item, index) => (
                                item.subMenu ? (
                                    <Menu.SubMenu
                                        key={`sub-${index}`}
                                        style={{ paddingLeft: collapsed ? 10 : 15 }}
                                        title={
                                            <div className='flex items-center'>
                                                {collapsed ? null : (
                                                    <img
                                                        src={item.icon}
                                                        alt={item.title}
                                                        className='flex items-center justify-center'
                                                        style={{ width: collapsed ? '35px' : '24px', height: collapsed ? '35px' : '24px' }}
                                                    />
                                                )}
                                                {!collapsed && (
                                                    <span className='pl-2.5' style={{ paddingLeft: collapsed ? 10 : 5 }}>{item.title}</span>
                                                )}
                                            </div>
                                        }
                                    >
                                        {item.subMenu.map(subItem => (
                                            <Menu.Item key={subItem.path}>
                                                <Link to={subItem.path}>{subItem.title}</Link>
                                            </Menu.Item>
                                        ))}
                                    </Menu.SubMenu>
                                ) : (
                                    <Menu.Item
                                        key={item.path}
                                        title={item.title}
                                        style={{ paddingLeft: collapsed ? 10 : '15px' }}
                                    >
                                        <div className='flex items-center dashboard-menu-img'>
                                            {collapsed ? null : (
                                                <Link to={item.path}>
                                                    <img
                                                        src={item.icon}
                                                        alt={item.title}
                                                        className='flex items-center justify-center'
                                                        style={{ width: collapsed ? '35px' : '24px', height: collapsed ? '35px' : '24px' }}
                                                    />
                                                </Link>
                                            )}
                                            {!collapsed && (
                                                <Link to={item.path} className='pl-2.5 inline-flex items-center'>
                                                    {item.title}
                                                    {item.title === 'Raise a query' && (
                                                        <img
                                                            src='/images/question-blink.png' // Path to your blinking GIF
                                                            alt='Blinking Question Mark'
                                                            className='ml-24 animate-pulse' // Optional Tailwind class
                                                            style={{
                                                                width: '25px', height: '25px',

                                                            }}
                                                        />
                                                    )}
                                                </Link>
                                            )}
                                        </div>
                                    </Menu.Item>
                                )
                            ))}
                        </Menu>

                    </Sider>
                    <div className='dash-cal-sesseion'>
                        {isLoggedIn}
                        <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
                            {loading ? (
                                <div className="loading-spinner">
                                    {/* Add your loading spinner here */}
                                </div>
                            ) : (
                                children
                            )}
                        </Content>
                        {path === '/student-dashboard/dashboard' ? (
                            <Sider width={300} style={{ background: '#fff', }}>
                                <div className='pt-5 cal-top'>
                                    <span className='text-[#30203A] text-base pl-2.5'>Hi,</span>
                                    <h4 className='text-[#30203A] text-base  pl-2.5'>{decrypt(user_data.name, encriptionKey)}</h4>
                                    <p className='text-[#30203A] text-base mb-5 pl-2.5'>( {course} )</p>
                                    <img src='/images/right-banner.png' alt='right-banner' />

                                    <div className='current-date'>
                                        <div className='date-info'>
                                            <h2>{date} {day}</h2>
                                            <span>{month} {year}</span>
                                        </div>
                                    </div>
                                </div>
                                <Calendar className='cal' fullscreen={false} dateCellRender={dateCellRender} onSelect={onSelect} />
                                {/* <div className='notify'>
                                <h4 className='text-[#666385] text-base text-center'>Notifications</h4>
                            </div>
                            <Timeline mode="left" className='sider-timeline'>
                                {timelineitems.map((item, index) => (
                                    <Timeline.Item key={index}>
                                        {item.children}
                                    </Timeline.Item>
                                ))}
                            </Timeline> */}
                            </Sider>
                        ) : ('')}
                        <Footer />
                    </div>
                </Layout>

            </Layout>
        </div>
    );
}

export default DashboardLayout