import React, { useState, useEffect } from "react";
import axios from 'axios';
import '../styles/counsellor.css';
import { Row, Col, Table, Input, Button, Space, Layout, Select } from "antd";
import CounsellorLayout from "./counsellor-layout";
import { useNavigate } from 'react-router-dom'
import { api_url } from '../components/constants';
import { decrypt, encriptionKey } from '../components/utils';
const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;

const DiagnosticSession = () => {

    const login_user = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    if (!login_user) {
        window.location.href = "/";
    }
    const user_data = login_user.user;

    const [loading, setLoading] = useState(true);
    const [classList, setClassList] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [selectedType, setSelectedType] = useState("");
    const [currentDateTime, setCurrentDateTime] = useState(new Date()); // eslint-disable-line

    const dateAfterAddition = new Date(currentDateTime);

    useEffect(() => {

        const fetchClassData = async () => {

            await axios.post(api_url + 'all-diagnostic-session', {
                user_id: user_data.id

            }).then((res) => {
                const p_list = res.data.ptm_list;

                if (p_list) {
                    const k = p_list.map((item, index) =>
                    ({
                        'sno': index + 1,
                        'session': item.class_name,
                        //'center': item.center,
                        'date': item.date + ' ' + item.start_time,
                        'duration': item.duration,
                        // 'time': item.time_slot,
                        'status': item.status,
                        'faculty': decrypt(item.faculty, encriptionKey),
                        //'desc': item.description,
                        'link': item.class_link,
                        'class_id': item.id,
                        'meeting_id': item.meeting_id,
                        'study_material': item.study_material,
                        'class_status': item.class_status,
                        'type': item.diagnostic_type,
                        'ptm_type': item.ptm_type
                    })
                    );

                    setClassList(k);
                    setLoading(false)
                }
            })
        }
        fetchClassData()
    }, [user_data.id])

    const joinZoomMeeting = (record) => {
        localStorage.setItem("session_type", record.ptm_type);
        navigate("/counsellor-dashboard/join-other-session/" + record.meeting_id);
    };

    const columns = [
        {
            key: '1',
            title: 'Sno',
            dataIndex: 'sno',
        },
        {
            key: '2',
            title: 'Session',
            dataIndex: 'session',
            align: 'center',
        },
        {
            key: '3',
            title: 'Faculty',
            dataIndex: 'faculty',
            align: 'center',
        },
        {
            key: '4',
            title: 'Type',
            dataIndex: 'type',
            align: 'center',
        },

        {
            key: '5',
            title: 'Date',
            dataIndex: 'date',
            align: 'center',
        },

        {
            key: '6',
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
        },
        {
            key: '7',
            title: 'Action',
            render: (record) => {
                return (
                    <Space>
                        {record.status === "Pending" && (
                            dateAfterAddition.getTime() >= new Date(record.date).getTime() - 15 * 60000 &&
                                dateAfterAddition.getTime() <= new Date(record.date).getTime() + record.duration * 60000 ? (
                                <Button
                                    className="bg-[#F0AA0C] text-white"
                                    style={{ borderRadius: 11 }}
                                    onClick={() => joinZoomMeeting(record)}
                                >
                                    Join Session
                                </Button>
                            ) : null
                        )}
                    </Space>
                );
            },
        },
    ];

    const searchItems = (searchValue) => {
        setSearchInput(searchValue);
        filterData(searchValue, selectedType);
    };

    const filterByType = (type) => {
        setSelectedType(type);
        filterData(searchInput, type);
    };

    const filterData = (search, type) => {
        let filteredData = classList;

        if (search) {
            filteredData = filteredData.filter((item) =>
                Object.values(item).join("").toLowerCase().includes(search.toLowerCase())
            );
        }

        if (type) {
            filteredData = filteredData.filter((item) => item.type === type);
        }

        setFilteredResults(filteredData);
    };


    return (
        <>
            <CounsellorLayout>
                {loading ? (
                    <div className="loaders"><img alt="loader" src='/images/loader.gif' /></div>
                ) : (
                    <Content
                        className="site-layout-background"
                        style={{
                            padding: 24,
                            background: '#fff',
                            width: '100%'
                        }}
                    >

                        <div className="mx-0 mt-0">
                            <Row>
                                <Col md={24} xs={24}>
                                    <div className="bg-[#114D89] px-5 py-2.5">
                                        <h1 className="text-white text-2xl ">DIAGNOSTIC SESSION</h1>
                                    </div>
                                </Col>

                            </Row>
                            <div className="py-5 px-5" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                <Row>

                                    <Col md={6} xs={24} style={{ display: 'flex', justifyContent: 'space-between' }} className='mb-5 mr-5'>

                                        <Search placeholder="Search...." onChange={(e) => searchItems(e.target.value)} />
                                    </Col>
                                    <Col md={6} xs={24}>
                                        <Select
                                            placeholder="Filter by Type"
                                            className="w-full"
                                            onChange={filterByType}
                                            allowClear
                                        >
                                            <Option value="SAT">SAT</Option>
                                            <Option value="TOEFL">TOEFL</Option>
                                            <Option value="IELTS">IELTS</Option>
                                            <Option value="English">English</Option>
                                        </Select>
                                    </Col>
                                    <Col md={24} className="responsive-table">

                                        <Table
                                            className="record"
                                            bordered
                                            columns={columns}
                                            dataSource={searchInput || selectedType ? filteredResults : classList}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>

                    </Content>
                )
                }
            </CounsellorLayout>
        </>
    )
}

export default DiagnosticSession