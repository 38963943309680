import React, { useEffect } from "react"; // eslint-disable-line
import axios from 'axios';
import '../styles/counsellor.css';
import { useParams } from "react-router-dom";
import { api_url } from '../components/constants';
import { decrypt, encriptionKey } from '../components/utils';



const JoinOtherSession = () => {
    const login_user = JSON.parse(localStorage.getItem('user'));
    const { meetingid } = useParams();

    if (!login_user) {
        window.location.href = "/";
    }

    const user_data = login_user.user;

    useEffect(() => {
        axios.post(api_url + 'faculty/join_class', {
            meeting_id: meetingid
        }).then((res) => {
            loadZoomSDK(res);
        }).catch((error) => {
            console.error('Error fetching class data:', error);
        });
        return () => {
            localStorage.removeItem("session_type");
        };
    }, []); // eslint-disable-line

    const loadZoomSDK = async (response) => {
        let class_data = response.data.data;

        const { ZoomMtg } = await import('@zoom/meetingsdk');

        if (class_data) {
            const sessionType = localStorage.getItem("session_type");
            const sessionPaths = {
                'Psychometric': '/teacher-dashboard/psychometric-session',
                'Roadmap': '/teacher-dashboard/roadmap-session',
                'Diagnostic': '/teacher-dashboard/diagnostic-session'
            };
            const leaveUrl = sessionPaths[sessionType] || '/teacher-dashboard/ptm-session';

            ZoomMtg.generateSDKSignature({
                meetingNumber: class_data.meeting_id,
                role: 1,
                sdkKey: class_data.sdk_key,
                sdkSecret: class_data.sdk_secret,
                success: function (signature) {
                    ZoomMtg.init({
                        leaveUrl: leaveUrl,
                        success: () => {
                            ZoomMtg.join({
                                meetingNumber: class_data.meeting_id,
                                sdkKey: class_data.sdk_key,
                                signature: signature,
                                userName: decrypt(user_data.name, encriptionKey) + '-' + user_data.id,
                                userEmail: decrypt(user_data.email, encriptionKey),
                                passWord: class_data.password,
                                success: (joinSuccess) => {
                                    console.log(joinSuccess);
                                },
                                error: (joinError) => {
                                    console.error('Error joining the meeting:', joinError);
                                }
                            });
                        },
                        error: (error) => {
                            console.log(error);
                        }
                    });
                }
            });
        } else {
            window.location.href = '/teacher-dashboard/ptm-session';
        }
    };

    return null;
};


export default JoinOtherSession;
