import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom'

import Login from './student-portal/login';
import Header from './components/header';
import NotFound from './components/error';
import Footer from './components/footer';
import TopBanner from './components/top-banner';
import StepForm from './components/step-form';
import EnquiryForm from './student-portal/general-enquiry';
import IntelligenceQuotient from './student-portal/general-intelligence-quotient';
import StudentProfile from './student-portal/student-profile-report';
import PyschometricReport from './student-portal/psychometric-report';
import DiagnosticReport from './student-portal/diagnostic-report';
import Dashboard from './student-dashboard/dashboard';
import GEF from './student-dashboard/gef';
import GIQ from './student-dashboard/giq';
import ProfileFormat from './student-dashboard/profile-format';
import Psychometric from './student-dashboard/psychometric';
import Diagnostic from './student-dashboard/diagnostic';
import TestRecords from './student-dashboard/test-record';
import PbsSession from './student-dashboard/pbs-session';
import PtmSession from './student-dashboard/ptm-session';
import FacultyPtmSession from './teacher-dashboard/ptm-session';
import TimelineRecords from './student-dashboard/record-timeline';
import MyTimelineRecords from './student-dashboard/my-timeline';
import PBSTimelineRecords from './counsellor-dashboard/pbs-timeline';
import CounsellorDashboard from './counsellor-dashboard/dashboard-home';
import StudentRecord from './counsellor-dashboard/student-record';
import PendingSession from './counsellor-dashboard/pending-session';
import UpcomingSession from './counsellor-dashboard/upcoming-session';
import CompletedSession from './counsellor-dashboard/completed-session';
import Notification from './counsellor-dashboard/notification';
import StudentTargets from './counsellor-dashboard/targets';
import StudentInfo from './counsellor-dashboard/student-info';
import DashboardLayout from './components/dashboard-layout';
import CalendarSchedule from './student-dashboard/calendar-schedule';
import SchoolRecord from './student-dashboard/school-record';
import CounsellorList from './counsellor-dashboard/counsellor-list';
import StudentList from './counsellor-dashboard/student-list';
import PBSList from './counsellor-dashboard/pbs-list';
import PendingPBSList from './counsellor-dashboard/pending-pbs-list';
import ApplicationStudentList from './counsellor-dashboard/application-student-list';
import TeacherLayout from './components/teacher-layout';
import TeacherDashboard from './teacher-dashboard/dashboard';
import Class9Record from './teacher-dashboard/class-9';
import MyStudent from './teacher-dashboard/my-student';
import DiagnosticStudent from './teacher-dashboard/diagnostic-student';
import Class11Record from './teacher-dashboard/class-11';
import Class12Record from './teacher-dashboard/class-12';
import UpcomingClass from './teacher-dashboard/Upcoming';
import CompletedClass from './teacher-dashboard/complete';
import CancelledClass from './teacher-dashboard/cancelled';
import AssessmentReport from './teacher-dashboard/assessment-report';
import UpdateAssessmentReport from './teacher-dashboard/update-assessment';
import AssessmentList from './teacher-dashboard/assessment-list';
import PerformanceReport from './teacher-dashboard/performance-report';
import UpdatePerformanceReport from './teacher-dashboard/update-performance';
import PerformanceRecord from './teacher-dashboard/performance-list';
import OpenDoubt from './teacher-dashboard/open-doubt';
import CloseDoubt from './teacher-dashboard/close-doubt';
import InPrcoessDoubt from './teacher-dashboard/inprocess-doubt';
import Quiz from './teacher-dashboard/quiz';
import DiagnosticQuiz from './student-portal/diagnostic';
import DiagnosticGuideline from './student-portal/diagnostic-guideline';
import DiagnosticResult from './student-dashboard/diagnostic-result';
import DiagnosticTeacherResult from './teacher-dashboard/diagnostic-result';
import Myclasses from './student-dashboard/my-classes';
import MyclassesNew from './student-dashboard/my-classes-new';
import FacultyclassesNew from './teacher-dashboard/my_classes';
import AssessmentReportList from './student-dashboard/assessment-report';
import AssessmentCourse from './student-dashboard/assessment';
import AssessmentContent from './student-dashboard/content';
import PerformanceReportList from './student-dashboard/performance-report';
import ViewPerformanceReport from './student-dashboard/view-performance';
import ViewAssessmentReport from './student-dashboard/view-assessment';
import DoubtSection from './student-dashboard/doubt-section';
import DoubtArea from './student-dashboard/doubt-area';
import FacultyDoubtSection from './teacher-dashboard/doubt-section';
import FacultyDoubtArea from './teacher-dashboard/doubt-area';
import ApplicationProcess from './student-dashboard/application-process';
import StudentApplication from './counsellor-dashboard/application-process';
import ApplicationStatus from './counsellor-dashboard/application-status';
import Documents from './counsellor-dashboard/documents';
import StudentDocuments from './student-dashboard/documents';
import Editor from './counsellor-dashboard/editor';
import JoinClass from './teacher-dashboard/join-class';
import JoinSession from './counsellor-dashboard/join-session';
import JoinPBSSession from './student-dashboard/join-session';
import JoinStudentClass from './student-dashboard/join-class';
import AttendanceReport from './teacher-dashboard/attendance-report';
import BulkAttendanceReport from './teacher-dashboard/bulk-attendance';
import ClassAttendanceReport from './teacher-dashboard/class-attendance';
import FacultyAttendance from './teacher-dashboard/faculty-attendance';
import FacultyAttendanceReport from './teacher-dashboard/faculty-attendance-report';
import StudentClassAttendanceReport from './teacher-dashboard/class-attendance-report';
import ViewRecordedClass from './teacher-dashboard/view-class';
import ViewStudentRecordedClass from './student-dashboard/view-class';
import ViewStudentClassFeedback from './teacher-dashboard/view-feedback';
import ReadingTool from './student-dashboard/reading-tool';
import ReadingToolPractice from './student-dashboard/reading-tool-practice';
import ReadingAnalysis from './student-dashboard/reading-analysis';
import Level2 from './student-dashboard/reading-level2';
import Round2Level1 from './student-dashboard/readingtool-round2';
import Round3Level1 from './student-dashboard/readingtool-round3';
import SpeedAnalysis from './student-dashboard/speed-analysis';
import ExtraClass from './student-dashboard/extra-class';
import DoubtClass from './student-dashboard/doubt-class';
import StudyMaterial from './student-dashboard/study-material';
import Application from './student-dashboard/application';
import CounsellorApplication from './counsellor-dashboard/application';
import DocumentUpload from './student-dashboard/my_document';
import BatchRequest from './student-dashboard/batch-request';
import RaiseTicket from './student-dashboard/raise-ticket';
import PTMSession from './counsellor-dashboard/ptm-session';
import JoinPtm from './counsellor-dashboard/join-ptm';
import JoinPTM from './teacher-dashboard/join-ptm';
import PsychometricSession from './counsellor-dashboard/psychometric-session';
import RoadmapSession from './counsellor-dashboard/roadmap-session';
import DiagnosticSession from './counsellor-dashboard/diagnostic-session';

import PsychometricSess from './teacher-dashboard/psychometric-session';
import RoadmapSess from './teacher-dashboard/roadmap-session';
import DiagnosticSess from './teacher-dashboard/diagnostic-session';
import JoinOtherSession from './counsellor-dashboard/join-other-session';
import JoinOtherSess from './teacher-dashboard/join-other-session';


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/components/header' element={<Header />} />
        <Route path='/components/footer' element={<Footer />} />
        <Route path='/components/top-banner' element={<TopBanner />} />
        <Route path='/helpers/setAuthToken' element={<setAuthToken />} />
        <Route path='/components/step-form' element={<StepForm />} />
        <Route path='/student-portal/general-enquiry' element={<EnquiryForm />} />
        <Route path='/student-portal/general-intelligence-quotient' element={<IntelligenceQuotient />} />
        <Route path='/student-portal/student-profile-report' element={<StudentProfile />} />
        <Route path='/student-portal/psychometric-report' element={<PyschometricReport />} />
        <Route path='/student-portal/diagnostic-report' element={<DiagnosticReport />} />
        <Route path='/student-dashboard/dashboard' element={<Dashboard />} />
        <Route path='/student-dashboard/gef' element={<GEF />} />
        <Route path='/student-dashboard/giq' element={<GIQ />} />
        <Route path='/student-dashboard/profile-format' element={<ProfileFormat />} />
        <Route path='/student-dashboard/psychometric' element={<Psychometric />} />
        <Route path='/student-dashboard/diagnostic' element={<Diagnostic />} />
        <Route path='/student-dashboard/test-record' element={<TestRecords />} />
        <Route path='/student-dashboard/pbs-session' element={<PbsSession />} />
        <Route path='/student-dashboard/ptm-session' element={<PtmSession />} />
        <Route path='/teacher-dashboard/ptm-session' element={<FacultyPtmSession />} />
        <Route path="/student-dashboard/pbs-timeline/:id" element={<TimelineRecords />} />
        <Route path="/student-dashboard/my-timeline" element={<MyTimelineRecords />} />
        <Route path='/counsellor-dashboard/dashboard-home' element={<CounsellorDashboard />} />
        <Route path='/counsellor-dashboard/student-record' element={<StudentRecord />} />
        <Route path='/counsellor-dashboard/pending-session' element={<PendingSession />} />
        <Route path='/counsellor-dashboard/upcoming-session' element={<UpcomingSession />} />
        <Route path='/counsellor-dashboard/completed-session' element={<CompletedSession />} />
        <Route path='/counsellor-dashboard/notification' element={<Notification />} />
        <Route path='/counsellor-dashboard/targets/:id' element={<StudentTargets />} />
        <Route path="/counsellor-dashboard/pbs-timeline/:id" element={<PBSTimelineRecords />} />
        <Route path="/counsellor-dashboard/student-info/:id" element={<StudentInfo />} />
        <Route path="/components/dashboard-layout" element={<DashboardLayout />} />
        <Route path="/student-dashboard/calendar-schedule" element={<CalendarSchedule />} />
        <Route path="/student-dashboard/school-record" element={<SchoolRecord />} />
        <Route path="/counsellor-dashboard/counsellor-list" element={<CounsellorList />} />
        <Route path="/counsellor-dashboard/student-list" element={<StudentList />} />
        <Route path="/counsellor-dashboard/pbs-list" element={<PBSList />} />
        <Route path="/counsellor-dashboard/pending-pbs-list" element={<PendingPBSList />} />
        <Route path="/counsellor-dashboard/application-student-list" element={<ApplicationStudentList />} />
        <Route path="/components/teacher-layout" element={<TeacherLayout />} />
        <Route path="/teacher-dashboard/dashboard" element={<TeacherDashboard />} />
        <Route path="/teacher-dashboard/my-student" element={<MyStudent />} />
        <Route path="/teacher-dashboard/class-9" element={<Class9Record />} />
        <Route path="/teacher-dashboard/diagnostic-student" element={<DiagnosticStudent />} />
        <Route path="/teacher-dashboard/class-11" element={<Class11Record />} />
        <Route path="/teacher-dashboard/class-12" element={<Class12Record />} />
        <Route path="/teacher-dashboard/Upcoming" element={<UpcomingClass />} />
        <Route path="/teacher-dashboard/complete" element={<CompletedClass />} />
        <Route path="/teacher-dashboard/cancelled" element={<CancelledClass />} />
        <Route path="/teacher-dashboard/open-doubt" element={<OpenDoubt />} />
        <Route path="/teacher-dashboard/close-doubt" element={<CloseDoubt />} />
        <Route path="/teacher-dashboard/inprocess-doubt" element={<InPrcoessDoubt />} />
        <Route path="/teacher-dashboard/quiz" element={<Quiz />} />
        <Route path="/student-portal/diagnostic" element={<DiagnosticQuiz />} />
        <Route path="/student-portal/diagnostic-guideline" element={<DiagnosticGuideline />} />
        <Route path="/student-dashboard/diagnostic-result" element={<DiagnosticResult />} />
        <Route path="/teacher-dashboard/diagnostic-result/:id" element={<DiagnosticTeacherResult />} />
        <Route path="/student-dashboard/my-classes" element={<Myclasses />} />
        <Route path="/student-dashboard/my-classes-new" element={<MyclassesNew />} />
        <Route path="/teacher-dashboard/my_classes" element={<FacultyclassesNew />} />
        <Route path="/teacher-dashboard/assessment-report/:id" element={<AssessmentReport />} />
        <Route path="/teacher-dashboard/assessment-list/:id" element={<AssessmentList />} />
        <Route path="/teacher-dashboard/update-assessment/:id" element={<UpdateAssessmentReport />} />
        <Route path="/teacher-dashboard/performance-report/:id" element={<PerformanceReport />} />
        <Route path="/teacher-dashboard/update-performance/:id" element={<UpdatePerformanceReport />} />
        <Route path="/teacher-dashboard/performance-list/:id" element={<PerformanceRecord />} />
        <Route path="/student-dashboard/assessment-report" element={<AssessmentReportList />} />
        <Route path="/student-dashboard/assessment" element={<AssessmentCourse />} />
        <Route path="/student-dashboard/content/:course_id" element={<AssessmentContent />} />
        <Route path="/student-dashboard/performance-report" element={<PerformanceReportList />} />
        <Route path="/student-dashboard/view-performance/:id" element={<ViewPerformanceReport />} />
        <Route path="/student-dashboard/view-assessment/:id" element={<ViewAssessmentReport />} />
        <Route path="/student-dashboard/doubt-section" element={<DoubtSection />} />
        <Route path="/teacher-dashboard/doubt-section" element={<FacultyDoubtSection />} />
        <Route path="/student-dashboard/doubt-area/:id" element={<DoubtArea />} />
        <Route path="/teacher-dashboard/doubt-area/:id" element={<FacultyDoubtArea />} />
        <Route path="/student-dashboard/application-process" element={<ApplicationProcess />} />
        <Route path="/counsellor-dashboard/application-process/:id" element={<StudentApplication />} />
        <Route path="/counsellor-dashboard/application-status/:id" element={<ApplicationStatus />} />
        <Route path="/counsellor-dashboard/documents/:docid" element={<Documents />} />
        <Route path="/student-dashboard/documents/:docid" element={<StudentDocuments />} />
        <Route path="/counsellor-dashboard/editor" element={<Editor />} />
        <Route path="/teacher-dashboard/join-class/:meetingid" element={<JoinClass />} />
        <Route path="/counsellor-dashboard/join-session/:meetingid" element={<JoinSession />} />
        <Route path="/student-dashboard/join-session/:meetingid" element={<JoinPBSSession />} />

        <Route path="/student-dashboard/join-class/:meetingid" element={<JoinStudentClass />} />
        <Route path="/teacher-dashboard/attendance-report" element={<AttendanceReport />} />
        <Route path="/teacher-dashboard/bulk-attendance" element={<BulkAttendanceReport />} />
        <Route path="/teacher-dashboard/class-attendance" element={<ClassAttendanceReport />} />
        <Route path="/teacher-dashboard/faculty-attendance" element={<FacultyAttendance />} />
        <Route path="/teacher-dashboard/faculty-attendance-report/:id" element={<FacultyAttendanceReport />} />
        <Route path="/teacher-dashboard/class-attendance-report/:id" element={<StudentClassAttendanceReport />} />
        <Route path="/teacher-dashboard/view-class/:meetingid" element={<ViewRecordedClass />} />
        <Route path="/student-dashboard/view-class/:meetingid" element={<ViewStudentRecordedClass />} />
        <Route path="/teacher-dashboard/view-feedback/:classId" element={<ViewStudentClassFeedback />} />
        <Route path="/student-dashboard/reading-tool" element={<ReadingTool />} />
        <Route path="/student-dashboard/reading-tool-practice" element={<ReadingToolPractice />} />
        <Route path="/student-dashboard/reading-analysis" element={<ReadingAnalysis />} />

        <Route path="/student-dashboard/reading-level2" element={<Level2 />} />
        <Route path="/student-dashboard/my-document" element={<DocumentUpload />} />
        <Route path="/student-dashboard/batch-request" element={<BatchRequest />} />
        <Route path="/student-dashboard/raise-ticket" element={<RaiseTicket />} />

        <Route path="/student-dashboard/readingtool-round2" element={<Round2Level1 />} />
        <Route path="/student-dashboard/readingtool-round3" element={<Round3Level1 />} />
        <Route path="/student-dashboard/speed-analysis" element={<SpeedAnalysis />} />
        <Route path="/student-dashboard/extra-class" element={<ExtraClass />} />
        <Route path="/student-dashboard/doubt-class" element={<DoubtClass />} />
        <Route path="/student-dashboard/study-material" element={<StudyMaterial />} />
        <Route path="/student-dashboard/application" element={<Application />} />
        <Route path="/counsellor-dashboard/application" element={<CounsellorApplication />} />
        <Route path="/counsellor-dashboard/ptm-session" element={<PTMSession />} />
        <Route path="/counsellor-dashboard/join-ptm/:meetingid" element={<JoinPtm />} />
        <Route path="/teacher-dashboard/join-ptm/:meetingid" element={<JoinPTM />} />

        <Route path="/counsellor-dashboard/psychometric-session" element={<PsychometricSession />} />
        <Route path="/counsellor-dashboard/roadmap-session" element={<RoadmapSession />} />
        <Route path="/counsellor-dashboard/diagnostic-session" element={<DiagnosticSession />} />

        <Route path="/teacher-dashboard/psychometric-session" element={<PsychometricSess />} />
        <Route path="/teacher-dashboard/roadmap-session" element={<RoadmapSess />} />
        <Route path="/teacher-dashboard/diagnostic-session" element={<DiagnosticSess />} />
        <Route path="*" element={<NotFound />} />

        <Route path="/counsellor-dashboard/join-other-session/:meetingid" element={<JoinOtherSession />} />
        <Route path="/teacher-dashboard/join-other-session/:meetingid" element={<JoinOtherSess />} />
      </Routes>
    </BrowserRouter>
  );
}



export default App;


